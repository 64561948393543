import React, { Suspense, lazy } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import LoadingScreen from './components/layout/loadingScreen';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      window.localStorage.getItem('ra-user-details') !== null ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
    {...rest}
    render={(props) => {
      const isAdmin = JSON.parse(window.localStorage.getItem('ra-user-details'))?.role_info?.is_admin
      return (
        isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      )
    }
    }
  />
  )
}

// ------ components ------
// auth
const LoginPage = lazy(() => import('pages/auth/login'));
const ForgotPassword = lazy(() => import('pages/auth/forgot_password'));
const ResetPassword = lazy(() => import('pages/auth/reset_password'));
const AccountConfirmation = lazy(() => import('pages/auth/account_confirmation'));
const Dashboard = lazy(() => import('pages/dashboard'));
const UserIndex = lazy(() => import('pages/user'));
const UserProfile = lazy(() => import('pages/user/profile'));
const UserForm = lazy(() => import('pages/user/userForm'));

const OrganisationIndex = lazy(() => import('pages/organisation'));
const OrganisationForm = lazy(() => import('pages/organisation/organisationForm'));
// const OrganisationShow = lazy(() => import('pages/organisation/organisationShow'));

const TaskIndex = lazy(() => import('pages/task'));

const Routers = () => (
  <Suspense fallback={<LoadingScreen route={true}/>}>
    <BrowserRouter>
      <Switch>
        <Route path="/" component={LoginPage} exact />
        <Route path="/login" component={LoginPage} exact />
        <Route path="/forgot_password" component={ForgotPassword} exact />
        <Route path="/reset_password/:token" component={ResetPassword} exact />
        <Route path="/account_confirmation" component={AccountConfirmation} exact />

        <PrivateRoute path="/dashboard" component={Dashboard} exact />
        <PrivateRoute path="/profile" component={UserProfile} exact />

        <AdminRoute path="/users" component={UserIndex} exact />
        <AdminRoute path="/users/new" component={UserForm} exact />
        <AdminRoute path="/users/edit" component={UserForm} exact />

        <AdminRoute path="/customers" component={OrganisationIndex} exact />
        <AdminRoute path="/customers/new" component={OrganisationForm} exact />
        <PrivateRoute path="/customers/edit" component={OrganisationForm} exact />
        <AdminRoute path="/tasks" component={TaskIndex} exact />
      </Switch>
    </BrowserRouter>
  </Suspense>
)

export default Routers;
