import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../AppContext';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const MainDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(3px);
  justify-content: center;
  color: white;
`;

const LoadingScreen = ({ route=false }) => {
  const { loading } = useContext(AppContext);
  if(!loading && !route) return <></>;
  return(
    <MainDiv>
      <Loader
        type="ThreeDots"
        color="#0b65c2"
        height={50}
        width={50}
        // timeout={3000} //3 secs
      />
    </MainDiv>
  )
};

export default LoadingScreen;
