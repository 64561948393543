import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../AppContext';
// icon
import { AiOutlineClose } from 'react-icons/ai';

const MainDiv = styled.div.attrs({
  className: 'flex justify-center mt-18 absolute w-full'
})``;

const ErrorDiv = styled.div.attrs({
  className: 'flex justify-between items-center'
})`
  position: absolute;
  background: ${({ theme, status }) => status ? theme.colors.alertState.success : theme.colors.alertState.error};
  min-width: 400px;
  max-width: 400px;
  border-radius: 4px;
  color: white;
  // left: 44%;
  // top: 15px;
  border: 0;
  min-height: 30px;
  padding: 10px;
  z-index: 200;
`;

const InfoMessage = () => {
  const { message, setMessage } = useContext(AppContext);
  const { status, info } = message || { status: false, info: ''};
  const [prevTimeout, setPrevTimeout] = useState(null)

  useEffect(() => {
    if(prevTimeout) clearTimeout(prevTimeout);
    const timeout = setTimeout(() => {
      setMessage('');
    }, 5000);
    setPrevTimeout(timeout)
  }, [message, setMessage])

  if(!message) return null
  return (
    <MainDiv>
      <ErrorDiv status={status}>
        {info}
        <AiOutlineClose onClick={() => setMessage('')}/>
      </ErrorDiv>
    </MainDiv>
  )
};

export default InfoMessage;
