const primary = {
  blue: "#0a66c2",
  // "#d2221ec7",
  // "#0a66c2",
  darkBlue: "#22345e",
  dullBlack: "#1d1d1d",
  blackCurrant: '#0D0C38',
  mist: '#F4F3FF',
  violet: '#716EFA',
  violetDark: '#5A58C7',
  violetLightest: '#716EFA',
  azure: '#6EC3FA',
  blaze: '#FF6900'
};

const secondry = {
  gray: 'gray',
  oslo: '#9C9D9E',
  french: '#CDCDCE',
  Iron: '#DEDEDF',
  athens: '#EEEEEF',
  wildSand: '#F5F5F5',
  white: '#FFFFFF'
}

const alertState = {
  error: '#F85050',
  success: '#008000a6'
};

const fontFamily = 'PT Sans';

const fontSize = {
  h1: '32px',
  xl: '24px',
  l: '21px',
  m: '18px',
  default: '16px',
  s: '14px',
  xs: '12px',
  xss: '10px'
};

const lightTheme = {
  colors: {
    primary,
    secondry,
    alertState
  },
  fontSize,
  fontFamily
};

export default lightTheme;
