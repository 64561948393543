import React, { useState, useEffect, createContext } from 'react';
import { useEffectOnce } from 'react-use';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffectOnce(() => {
    const userDetails = localStorage.getItem('ra-user-details')
    if(userDetails) {
      setUser(JSON.parse(userDetails))
    }
  });

  useEffect(() => {
    localStorage.setItem('ra-user-details', JSON.stringify(user));
  }, [user])

  return (
    <AppContext.Provider value={{user, setUser, message, setMessage, loading, setLoading}}>
      {children}
    </AppContext.Provider>
  )
}
