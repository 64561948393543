import React from 'react';
import Routers from './Routers';
import { ThemeProvider } from 'styled-components';
import lightTheme from './themes/light';
// component
import InfoMessage from './components/layout/infoMessage';
import LoadingScreen from './components/layout/loadingScreen';
// context
import { AppProvider } from './AppContext';
// css
import 'semantic-ui-css/semantic.min.css'
import "./styles/index.css";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <AppProvider>
        <LoadingScreen/>
        <InfoMessage/>
        <Routers/>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
